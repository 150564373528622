/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// React
import { useEffect, useState } from 'react';

// Lib dependencies
import { useTranslation } from 'react-i18next';

// Components
import LoadingOverlay from '../../../shared/components/organisms/LoadingOverlay';

// Services
import IpServices from '../../../shared/services/ip.service';

// Others
import useStore from '../../../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Provider                                  */
/* -------------------------------------------------------------------------- */

const LanguageProvider = (props) => {
  /* ******************************** LOGIC ******************************* */

  const [isLoading, setIsLoading] = useState(true);

  const { children } = props;

  const { i18n } = useTranslation();

  const { loadUser, loadCountry } = useStore();

  async function fetchData(_code) {
    const countryData = await import('../../locales/en/phone-countries-en.json');
    const code = _code?.toLowerCase();

    if (code in countryData) {
      return countryData[code];
    }

    return null;
  }

  useEffect(() => {
    const detectLanguage = async () => {
      try {
        const response = await IpServices.get();

        loadUser(response);

        const { countryCode } = response;
        fetchData(countryCode);
        const val = await fetchData(countryCode);
        loadCountry(val);
        i18n.changeLanguage((navigator.language || navigator.userLanguage)?.split('-')[0] === 'fr' ? 'fr' : 'en');
      } catch (error) {
      } finally {
        setIsLoading(false);
      }
    };

    detectLanguage();
  }, []);

  /* ******************************** RENDERING ******************************* */

  if (isLoading) {
    return <LoadingOverlay visible />;
  }

  return children;
};

export default LanguageProvider;
