// Lib dependencies
import { Routes, Route, Navigate } from 'react-router-dom';

// Configs
import appRoutes from './configs/appRoutes.configs';
import routesPaths from './configs/routesPaths.configs';

// Others
import useStore from '../shared/store';

/* -------------------------------------------------------------------------- */
/*                                  Router                                  */
/* -------------------------------------------------------------------------- */

const Router = () => {
  const { entity } = useStore();

  /* ******************************** RENDERING ******************************* */

  return (
    <Routes>
      {Object.entries(appRoutes).map(([key, route]) => {
        const { path } = route;

        // Skip entity routes if entity is not loaded
        if (!entity.isLoaded && (path === routesPaths.category || path === routesPaths.product)) {
          return null;
        }

        // Skip product route if entity is loaded and has 'category' type
        // Skip category route if entity is loaded and has 'product' type
        if (entity.isLoaded) {
          // Case entity has 'category' type => skip product route
          // Case entity has 'product' type => skip category route
          if (
            (entity.type === 'category' && path === routesPaths.product) ||
            (entity.type === 'product' && path === routesPaths.category)
          ) {
            return null;
          }
          // Case: entity is reported
          if (entity.data.reported) {
            // Case entity has 'category' type  => redirect user from 'home' page to 'cateory' page
            if (entity.type === 'category' && path === routesPaths.home) {
              return (
                <Route
                  key={key}
                  path="*"
                  element={<Navigate to={`${appRoutes.category.route}?UID=${entity.data.reference}`} />}
                />
              );
            }

            // Case entity has 'product' type  => redirect user from 'home' page to 'product' page
            if (entity.type === 'product' && path === routesPaths.home) {
              return (
                <Route
                  key={key}
                  path="*"
                  element={<Navigate to={`${appRoutes.product.route}?UID=${entity.data.reference}`} />}
                />
              );
            }
          }
        }

        // Skip certification routes if entity is already certified
        if (entity.isLoaded && entity.data.certified && path === routesPaths.certification) {
          return null;
        }

        return <Route key={key} path={route.route} element={route.element} />;
      })}
      <Route path="*" element={<Navigate to={appRoutes.notFound.route} />} />
    </Routes>
  );
};

export default Router;
