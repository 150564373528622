const routesPaths = {
  notFound: '/404',
  home: '/',
  category: '/category',
  product: '/product',
  certification: '/certification',
  issueRequest: '/issue-request',
  success: '/certification/success',
  tutorial: '/tutoriel',
  FAQ: '/FAQ',
  entityNotFound: '/out',
};

export default routesPaths;
