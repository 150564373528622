/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import { Text } from '@mantine/core';
import PropTypes from 'prop-types';

// Style
import useStyles from './styles';

// Helpers
import UtilsHelper from '../../../helpers/utils.helper';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const Typography = (props) => {
  const { children, type = 'text', color, ff, size, lts, fw, ta, mb, mt, width } = props;

  const style = {
    color,
    fontFamily: ff,
    fontSize: size && UtilsHelper.toRem(size),
    letterSpacing: lts,
    fontWeight: fw,
    textAlign: ta,
    marginBottom: mb,
    marginTop: mt,
    width,
  };

  const { classes } = useStyles();

  return (
    <Text className={classes[type]} style={style}>
      {children}
    </Text>
  );
};

/* -------------------------------------------------------------------------- */
/*                                    Props                                   */
/* -------------------------------------------------------------------------- */

Typography.propTypes = {
  /**
   * The content to be rendered inside the Typography component.
   */
  children: PropTypes.node,

  /**
   * The type of the typography. Use 'title' for titles, 'subtitle' for subtitles, and 'text' for regular text.
   */
  type: PropTypes.oneOf(['title', 'subtitle', 'text', 'product']),

  /**
   * The color of the typography. Accepts any valid CSS color value.
   */
  color: PropTypes.string,

  /**
   * The font family to be used. Use 'primary' for the primary font family and 'secondary' for the secondary font family.
   */
  ff: PropTypes.oneOf(['primary', 'secondary']),

  /**
   * ont size in pixels (automatically converted to rem).
   * Default: 12px on mobile, 16px on tablet and desktop (for 'text' & 'subtitle types).
   * 20px on mobile, 30px on tablet and desktop (for 'title' type).
   */
  size: PropTypes.number,

  /**
   * The letter spacing of the typography. Accepts a number or string representing the letter spacing value.
   */
  lts: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * The font weight of the typography. Accepts a number or string representing the font weight value.
   */
  fw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

  /**
   * The text alignment of the typography. Use 'left' for left alignment, 'right' for right alignment,
   * 'center' for center alignment, 'justify' for justified alignment, 'start' for start alignment,
   * and 'end' for end alignment.
   */
  ta: PropTypes.oneOf(['left', 'right', 'center', 'justify', 'start', 'end']),

  /**
   * The margin bottom of the typography. Accepts a number or string representing the margin bottom value.
   */
  mb: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  /**
   * The width  of the typography box Accepts a string.
   */
  width: PropTypes.oneOfType([PropTypes.string]),
};

Typography.defaultProps = {
  children: undefined,
  type: 'text',
  color: undefined,
  ff: undefined,
  size: undefined,
  lts: undefined,
  fw: undefined,
  ta: undefined,
  mb: undefined,
  width: undefined,
};

export default Typography;
